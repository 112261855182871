import React, { Fragment } from 'react';
import { List, Datagrid, AutocompleteInput, ReferenceInput, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import { LockField, ValidateField } from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_model.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }

// TypeTextField.defaultProps = {
//     addLabel: true,
// };
var lockeds = [];
lockeds.push({ id: 0, name: 'Non-verrouillé' });
lockeds.push({ id: 1, name: "Verrouillé" });

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn resettable />
        <ReferenceInput label="Marque" source="brand_id" emptyText="Tous"
            sort={{ field: 'name', order: 'ASC' }}
            reference="vehicle_brand" perPage={99999} alwaysOn resettable>
            <AutocompleteInput optionText="name"

            />
        </ReferenceInput>
        <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
            reference="vehicle_type" perPage={20} sort={{ field: 'name', order: 'ASC' }}
            alwaysOn>
            <SelectInput
                optionText='name' emptyText="Tous"

            />
        </ReferenceInput>
        <SelectInput label="État" source="is_locked" choices={lockeds}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1' alwaysOn
        />

    </Filter>
);

const ListVehicleModel = ({ permissions, ...props }) => (
    <List title={<Title />} {...props}
        filters={<ListFilter permissions={permissions} />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name" label="Nom" />
            <TextField source="vehicle_brand_name" label="Marque" />
            <TextField source="vehicle_type_name" label="Type de véhicule" />
            {/* <TextField source="seats" label="Sièges"/> */}
            {/* <ImageField source="luggages" label="Icone pour la sélection" size='48'/> */}
            <ValidateField source="is_locked" label="Verrouillé" />
            {/* <EditButton/> */}
        </Datagrid>
    </List>
);

export default ListVehicleModel;

