import React from 'react';
// import PDFViewer from 'pdf-viewer-reactjs';
import { withRouter } from 'react-router-dom'
import { showNotification, Confirm, crudGetOne, Button, Loading } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import FileSlider from './file-slider'
import IconCancel from '@material-ui/icons/Cancel';
import { BACKEND_API_KEY, BACKEND_FILE_URL } from '../../util/variant'
// import BackButton from '../back-button'
import '../../App.css'
import Axios from 'axios';
import { he } from 'date-fns/locale';

function isSafari() {

  // Get the user-agent string 
  let userAgentString =
    navigator.userAgent;

  // Detect Chrome 
  let chromeAgent =
    userAgentString.indexOf("Chrome") > -1;
  if (chromeAgent) return false;

  // Detect Internet Explorer 
  let IExplorerAgent =
    userAgentString.indexOf("MSIE") > -1 ||
    userAgentString.indexOf("rv:") > -1;

  if (IExplorerAgent) return false;

  // Detect Firefox 
  let firefoxAgent =
    userAgentString.indexOf("Firefox") > -1;

  if (firefoxAgent) return false;


  // Detect Opera 
  let operaAgent =
    userAgentString.indexOf("OP") > -1;

  if (operaAgent) return false;

  // Detect Safari 
  let safariAgent =
    userAgentString.indexOf("Safari") > -1;
  return safariAgent;

}

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  padding: 0,
};

const styles = {
  flex: { display: 'flex', marginLeft: '50' },
  // flexColumn: { display: 'flex', flexDirection: 'column', marginLeft:'3em' },
  flexColumn: { display: 'flex', flexDirection: 'column', marginLeft: '0em', marginRight: '1em' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
  button: { flex: 1, marginTop: '1em' },
};


class MediaViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      fileUrl: '',
      title: '',
      // titleHeight:0,
      hasTitle: false,
      width: 800,
      height: 800,
      showDialog: false,

      // showBackButton: false,
    }
  }

  onClick(ext) {
    // alert('Clocked');
    // if (ext === 'pdf') return;
    if (this.state.fileUrl != '')
      this.setState({ showDialog: true });
    else
      alert('Aucun document à afficher');
  }

  onClose() {
    // alert('Clocked');
    this.setState({ showDialog: false });
  }

  loadMedia(viewerId, url) {

    console.log("MediaViewer download media file " + url);
    let ext = url.split('.').pop();
    console.log("MediaViewer file extension " + ext);


    // if (ext === "pdf") {
    //   // const base64 = "JVBERi0xLjEKJcKlwrHDqwoKMSAwIG9iagogIDw8IC9UeXBlIC9DYXRhbG9nCiAgICAgL1BhZ2VzIDIgMCBSCiAgPj4KZW5kb2JqCgoyIDAgb2JqCiAgPDwgL1R5cGUgL1BhZ2VzCiAgICAgL0tpZHMgWzMgMCBSXQogICAgIC9Db3VudCAxCiAgICAgL01lZGlhQm94IFswIDAgMzAwIDE0NF0KICA+PgplbmRvYmoKCjMgMCBvYmoKICA8PCAgL1R5cGUgL1BhZ2UKICAgICAgL1BhcmVudCAyIDAgUgogICAgICAvUmVzb3VyY2VzCiAgICAgICA8PCAvRm9udAogICAgICAgICAgIDw8IC9GMQogICAgICAgICAgICAgICA8PCAvVHlwZSAvRm9udAogICAgICAgICAgICAgICAgICAvU3VidHlwZSAvVHlwZTEKICAgICAgICAgICAgICAgICAgL0Jhc2VGb250IC9UaW1lcy1Sb21hbgogICAgICAgICAgICAgICA+PgogICAgICAgICAgID4+CiAgICAgICA+PgogICAgICAvQ29udGVudHMgNCAwIFIKICA+PgplbmRvYmoKCjQgMCBvYmoKICA8PCAvTGVuZ3RoIDU1ID4+CnN0cmVhbQogIEJUCiAgICAvRjEgMTggVGYKICAgIDAgMCBUZAogICAgKEhlbGxvIFdvcmxkKSBUagogIEVUCmVuZHN0cmVhbQplbmRvYmoKCnhyZWYKMCA1CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDAxOCAwMDAwMCBuIAowMDAwMDAwMDc3IDAwMDAwIG4gCjAwMDAwMDAxNzggMDAwMDAgbiAKMDAwMDAwMDQ1NyAwMDAwMCBuIAp0cmFpbGVyCiAgPDwgIC9Sb290IDEgMCBSCiAgICAgIC9TaXplIDUKICA+PgpzdGFydHhyZWYKNTY1CiUlRU9GCg==";
    //   // this.setState({ data: "data:application/pdf;base64," + base64 });
    //   return;
    // }


    const sessionId = localStorage.getItem('session_id');
    url += "?app_key=" + BACKEND_API_KEY;
    Axios.get(url, {
      headers: { 'session-id': sessionId },
      responseType: "arraybuffer"
      // responseType: "text",
      // responseEncoding: "base64",
    })
      .then((res) => {

        // var base64 = Buffer.from(res.data, "base64");
        var base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )

        // if (ext === 'pdf') {
        //   base64 = Buffer.from(res.data, "binary");
        // }
        // console.log("Base64 = " + base64);

        if (ext === "pdf") {
          // base64 = "JVBERi0xLjEKJcKlwrHDqwoKMSAwIG9iagogIDw8IC9UeXBlIC9DYXRhbG9nCiAgICAgL1BhZ2VzIDIgMCBSCiAgPj4KZW5kb2JqCgoyIDAgb2JqCiAgPDwgL1R5cGUgL1BhZ2VzCiAgICAgL0tpZHMgWzMgMCBSXQogICAgIC9Db3VudCAxCiAgICAgL01lZGlhQm94IFswIDAgMzAwIDE0NF0KICA+PgplbmRvYmoKCjMgMCBvYmoKICA8PCAgL1R5cGUgL1BhZ2UKICAgICAgL1BhcmVudCAyIDAgUgogICAgICAvUmVzb3VyY2VzCiAgICAgICA8PCAvRm9udAogICAgICAgICAgIDw8IC9GMQogICAgICAgICAgICAgICA8PCAvVHlwZSAvRm9udAogICAgICAgICAgICAgICAgICAvU3VidHlwZSAvVHlwZTEKICAgICAgICAgICAgICAgICAgL0Jhc2VGb250IC9UaW1lcy1Sb21hbgogICAgICAgICAgICAgICA+PgogICAgICAgICAgID4+CiAgICAgICA+PgogICAgICAvQ29udGVudHMgNCAwIFIKICA+PgplbmRvYmoKCjQgMCBvYmoKICA8PCAvTGVuZ3RoIDU1ID4+CnN0cmVhbQogIEJUCiAgICAvRjEgMTggVGYKICAgIDAgMCBUZAogICAgKEhlbGxvIFdvcmxkKSBUagogIEVUCmVuZHN0cmVhbQplbmRvYmoKCnhyZWYKMCA1CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDAxOCAwMDAwMCBuIAowMDAwMDAwMDc3IDAwMDAwIG4gCjAwMDAwMDAxNzggMDAwMDAgbiAKMDAwMDAwMDQ1NyAwMDAwMCBuIAp0cmFpbGVyCiAgPDwgIC9Sb290IDEgMCBSCiAgICAgIC9TaXplIDUKICA+PgpzdGFydHhyZWYKNTY1CiUlRU9GCg==";
          // this.setState({ data: "data:application/pdf;base64," + base64 });
          const objects = document.getElementsByClassName("imgv-" + viewerId);
          for (let i = 0; i < objects.length; i++) {
            if (!isSafari())
              objects[i].data = `data:application/pdf;base64, ${base64}`;
            else
              objects[i].src = `data:application/pdf;base64, ${base64}`;
          }
        }
        else {
          const images = document.getElementsByClassName("imgv-" + viewerId);
          for (let i = 0; i < images.length; i++) {
            images[i].src = `data:image/png;base64, ${base64}`;
          }
        }

      })
  }

  componentWillMount() {
    console.log("MediaViewer props:");
    console.log(this.props);
    var url;
    const { fileUrl, title, width, height, files, viewerId } = this.props;
    // this.setState({ files: files, width: width, height: height });
    this.setState({ files: files });
    url = fileUrl;

    if (typeof (title) !== 'undefined' && title != null) {
      this.setState({ title: title });
      if (title != '')
        this.setState({ hasTitle: true });
    }

    if (typeof (fileUrl) !== 'undefined' && fileUrl != null) {
      this.setState({ fileUrl: url });
    }
    else {
      console.log("MediaViewer url: " + this.props.match.params.url);

      url = this.props.match.params.url;
      if (typeof (url) !== 'undefined' && url !== 'undefined' && url !== null) {
        url = url.replace('|', '/');
        url = url.replace('|', '/');
        url = BACKEND_FILE_URL + url;
        this.setState({ fileUrl: url });
      }

    }
    console.log("MediaViewer componentWillMount " + url);
    console.log("MediaViewer componentWillMount " + viewerId);

    this.loadMedia(viewerId, url);


  }

  componentDidUpdate() {
    const { viewerId } = this.props;
    console.log("MediaViewer componentDidUpdate " + this.state.fileUrl);
    console.log("MediaViewer componentDidUpdate " + viewerId);
    this.loadMedia(viewerId, this.state.fileUrl);

  }

  render() {
    // console.log("Render");
    // console.log('State url: ' + this.state.fileUrl);
    var { round, viewerId } = this.props;
    console.log("render " + viewerId);
    console.log();
    if (round === 'undefined')
      round = '0%';

    let ext = this.state.fileUrl.split('.').pop();

    if (ext === 'pdf') {
      return (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1em', marginRight: '1em', }} >

            {/* type="application/pdf" */}
            {/* {!isSafari() && <object data="data:application/pdf;base64,JVBERi0xLjEKJcKlwrHDqwoKMSAwIG9iagogIDw8IC9UeXBlIC9DYXRhbG9nCiAgICAgL1BhZ2VzIDIgMCBSCiAgPj4KZW5kb2JqCgoyIDAgb2JqCiAgPDwgL1R5cGUgL1BhZ2VzCiAgICAgL0tpZHMgWzMgMCBSXQogICAgIC9Db3VudCAxCiAgICAgL01lZGlhQm94IFswIDAgMzAwIDE0NF0KICA+PgplbmRvYmoKCjMgMCBvYmoKICA8PCAgL1R5cGUgL1BhZ2UKICAgICAgL1BhcmVudCAyIDAgUgogICAgICAvUmVzb3VyY2VzCiAgICAgICA8PCAvRm9udAogICAgICAgICAgIDw8IC9GMQogICAgICAgICAgICAgICA8PCAvVHlwZSAvRm9udAogICAgICAgICAgICAgICAgICAvU3VidHlwZSAvVHlwZTEKICAgICAgICAgICAgICAgICAgL0Jhc2VGb250IC9UaW1lcy1Sb21hbgogICAgICAgICAgICAgICA+PgogICAgICAgICAgID4+CiAgICAgICA+PgogICAgICAvQ29udGVudHMgNCAwIFIKICA+PgplbmRvYmoKCjQgMCBvYmoKICA8PCAvTGVuZ3RoIDU1ID4+CnN0cmVhbQogIEJUCiAgICAvRjEgMTggVGYKICAgIDAgMCBUZAogICAgKEhlbGxvIFdvcmxkKSBUagogIEVUCmVuZHN0cmVhbQplbmRvYmoKCnhyZWYKMCA1CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDAxOCAwMDAwMCBuIAowMDAwMDAwMDc3IDAwMDAwIG4gCjAwMDAwMDAxNzggMDAwMDAgbiAKMDAwMDAwMDQ1NyAwMDAwMCBuIAp0cmFpbGVyCiAgPDwgIC9Sb290IDEgMCBSCiAgICAgIC9TaXplIDUKICA+PgpzdGFydHhyZWYKNTY1CiUlRU9GCg=="
              style={{ marginLeft: '30px', width: '600px', height: '500px', backgroundColor: 'white' }} onClick={() => this.onClick()}>
            </object>} */}
            {!isSafari() && <object class={"imgv-" + viewerId}
              // style={{ marginLeft: '0px', width: '1000px', height: '1000px', backgroundColor: 'white' }}
              style={{ width: this.state.width, height: this.state.height, marginLeft: 0, borderRadius: round, backgroundColor: 'white' }}
              onClick={() => this.onClick(ext)}>
            </object>}

            {/* class={"imgv-" + viewerId} id={"1-image-" + viewerId}  */}
            {/* {isSafari() && <img type="application/pdf" src="data:application/pdf;base64, JVBERi0xLjEKJcKlwrHDqwoKMSAwIG9iagogIDw8IC9UeXBlIC9DYXRhbG9nCiAgICAgL1BhZ2VzIDIgMCBSCiAgPj4KZW5kb2JqCgoyIDAgb2JqCiAgPDwgL1R5cGUgL1BhZ2VzCiAgICAgL0tpZHMgWzMgMCBSXQogICAgIC9Db3VudCAxCiAgICAgL01lZGlhQm94IFswIDAgMzAwIDE0NF0KICA+PgplbmRvYmoKCjMgMCBvYmoKICA8PCAgL1R5cGUgL1BhZ2UKICAgICAgL1BhcmVudCAyIDAgUgogICAgICAvUmVzb3VyY2VzCiAgICAgICA8PCAvRm9udAogICAgICAgICAgIDw8IC9GMQogICAgICAgICAgICAgICA8PCAvVHlwZSAvRm9udAogICAgICAgICAgICAgICAgICAvU3VidHlwZSAvVHlwZTEKICAgICAgICAgICAgICAgICAgL0Jhc2VGb250IC9UaW1lcy1Sb21hbgogICAgICAgICAgICAgICA+PgogICAgICAgICAgID4+CiAgICAgICA+PgogICAgICAvQ29udGVudHMgNCAwIFIKICA+PgplbmRvYmoKCjQgMCBvYmoKICA8PCAvTGVuZ3RoIDU1ID4+CnN0cmVhbQogIEJUCiAgICAvRjEgMTggVGYKICAgIDAgMCBUZAogICAgKEhlbGxvIFdvcmxkKSBUagogIEVUCmVuZHN0cmVhbQplbmRvYmoKCnhyZWYKMCA1CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDAxOCAwMDAwMCBuIAowMDAwMDAwMDc3IDAwMDAwIG4gCjAwMDAwMDAxNzggMDAwMDAgbiAKMDAwMDAwMDQ1NyAwMDAwMCBuIAp0cmFpbGVyCiAgPDwgIC9Sb290IDEgMCBSCiAgICAgIC9TaXplIDUKICA+PgpzdGFydHhyZWYKNTY1CiUlRU9GCg==" alt='' style={{ width: this.state.width, height: this.state.height, marginLeft: 0, borderRadius: round }} onClick={() => this.onClick()} />} */}
            {/* {isSafari() && <img class={"imgv-" + viewerId} alt='' style={{ width: this.state.width, height: 'auto', marginLeft: 0, borderRadius: round }} onClick={() => this.onClick(ext)} />} */}

            {isSafari() && <iframe class={"imgv-" + viewerId} style={{ width: this.state.width, height: this.state.height, marginLeft: 0, borderRadius: round }} frameborder="0" allowfullscreen></iframe>}
            {/* {isSafari() && <img class={"imgv-" + viewerId} alt='' style={{ marginLeft: '0px', width: '1000px', height: '1000px', backgroundColor: 'white' }} onClick={() => this.onClick(ext)} />} */}

            {this.state.hasTitle && <span style={{ marginLeft: 0, marginRight: 0, height: 'auto' }}>{this.state.title}</span>}

          </div>

          <Dialog fullWidth={true}
            maxWidth="1000xs"
            open={this.state.showDialog}

            aria-label="Previsualisation">

            <DialogTitle></DialogTitle>

            <DialogContent>
              <div style={{ width: '1000px', height: 'auto', border: '1px solid white' }}>
                {!isSafari() && <object class={"imgv-" + viewerId}
                  style={{ marginLeft: '0px', width: '1000px', height: 'auto', backgroundColor: 'white' }}>
                </object>}

                {/* {isSafari() && <img src="data:application/pdf;base64,JVBERi0xLjEKJcKlwrHDqwoKMSAwIG9iagogIDw8IC9UeXBlIC9DYXRhbG9nCiAgICAgL1BhZ2VzIDIgMCBSCiAgPj4KZW5kb2JqCgoyIDAgb2JqCiAgPDwgL1R5cGUgL1BhZ2VzCiAgICAgL0tpZHMgWzMgMCBSXQogICAgIC9Db3VudCAxCiAgICAgL01lZGlhQm94IFswIDAgMzAwIDE0NF0KICA+PgplbmRvYmoKCjMgMCBvYmoKICA8PCAgL1R5cGUgL1BhZ2UKICAgICAgL1BhcmVudCAyIDAgUgogICAgICAvUmVzb3VyY2VzCiAgICAgICA8PCAvRm9udAogICAgICAgICAgIDw8IC9GMQogICAgICAgICAgICAgICA8PCAvVHlwZSAvRm9udAogICAgICAgICAgICAgICAgICAvU3VidHlwZSAvVHlwZTEKICAgICAgICAgICAgICAgICAgL0Jhc2VGb250IC9UaW1lcy1Sb21hbgogICAgICAgICAgICAgICA+PgogICAgICAgICAgID4+CiAgICAgICA+PgogICAgICAvQ29udGVudHMgNCAwIFIKICA+PgplbmRvYmoKCjQgMCBvYmoKICA8PCAvTGVuZ3RoIDU1ID4+CnN0cmVhbQogIEJUCiAgICAvRjEgMTggVGYKICAgIDAgMCBUZAogICAgKEhlbGxvIFdvcmxkKSBUagogIEVUCmVuZHN0cmVhbQplbmRvYmoKCnhyZWYKMCA1CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDAxOCAwMDAwMCBuIAowMDAwMDAwMDc3IDAwMDAwIG4gCjAwMDAwMDAxNzggMDAwMDAgbiAKMDAwMDAwMDQ1NyAwMDAwMCBuIAp0cmFpbGVyCiAgPDwgIC9Sb290IDEgMCBSCiAgICAgIC9TaXplIDUKICA+PgpzdGFydHhyZWYKNTY1CiUlRU9GCg==" class={"imgv-" + viewerId} id={"1-image-" + viewerId} alt='' style={{ width: this.state.width, height: this.state.height, marginLeft: 0, borderRadius: round }} onClick={() => this.onClick()} />} */}
                {/* {isSafari() && <img class={"imgv-" + viewerId} alt='' style={{ width: this.state.width, height: this.state.height, marginLeft: 0, borderRadius: round }} onClick={() => this.onClick()} />} */}
                {isSafari() && <img class={"imgv-" + viewerId} alt='' style={{ marginLeft: '0px', width: '1000px', height: 'auto', backgroundColor: 'white' }} />}

              </div>
            </DialogContent>
            <DialogActions>
              <Button label="Fermer" onClick={() => this.onClose()}>
                <IconCancel />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    return (
      <div>

        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0em', marginRight: '1em', width: this.state.width }}>
          <img class={"imgv-" + viewerId} id={"1-image-" + viewerId} alt=''
            style={{ width: 400, height: 'auto', marginLeft: 0, borderRadius: round }}

            onClick={() => this.onClick("")} />
          {this.state.hasTitle && <span style={{ marginLeft: 0, marginRight: 0, height: 'auto' }}>{this.state.title}</span>}

        </div>

        <Dialog fullWidth={true}
          maxWidth="1000xs"
          open={this.state.showDialog}

          aria-label="Previsualisation">

          <DialogTitle></DialogTitle>

          <DialogContent>
            <div style={{ width: '100%', height: '100%', border: '1px solid white' }}>

              <img class={"imgv-" + viewerId}
                id={"2-image-" + viewerId}

                alt=''
                style={{
                  width: '80%', height: 'auto',
                  maxWidth: '2000px',
                  maxHeight: '2000px',
                }} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button label="Fermer" onClick={() => this.onClose()}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(MediaViewer);