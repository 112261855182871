
import React from 'react';
import {
    TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm,
    SelectInput, AutocompleteInput, RadioButtonGroupInput, required, ImageInput, useTranslate, ReferenceInput
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';

import BrandReferenceInput from './control/brand-reference-input';

import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import { locationTypes } from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_model.edit')}: {record ? `${record.name}` : ''}</span>;
};

const validateSeats = [required(), minValue(1, 'La valeur doit ne pas être inférieure à 1')];

const EditVehicleModel = ({ classes, permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <Edit title={<Title />} {...props} actions={<EditActions />}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>

                <TextInput source="name" label={translate('name')} validate={required()} />
                {/* <BrandReferenceInput label="Marque" source="brand_id" reference="vehicle_brand" validate={required()}/> */}
                <ReferenceInput label="Marque" source="brand_id" perPage={99999}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="vehicle_brand" validate={required()}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="vehicle_type" validate={required()}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                {/* <NumberInput source="seats" label = "Nombre de places" initialValue={1} step={1} validate={validateSeats}/> */}


                <RadioButtonGroupInput source="is_locked" label="Verrouillé?" choices={[
                    { id: 0, name: 'Non' },
                    { id: 1, name: 'Oui' },
                ]} initialValue="0" />

            </SimpleForm>
        </Edit>
    )
};

export default EditVehicleModel;