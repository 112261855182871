
import React, { Link, Component } from 'react';
import { Pagination, List, Labeled, ReferenceField, DateField, ReferenceManyField, Datagrid, TextField, Show, useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form'
import Divider from '@material-ui/core/Divider';
import { DocApproveButton, WalletResetButton, DocRejectButton, DeleteButton, AvatarButton, WalletEditButton } from '../../control/button/index';

import ImageViewer from '../../control/viewer/image-viewer';

import CardActions from '@material-ui/core/CardActions';

import {
    DataStatusField, DocStatusField, OrderStatusField, FormattedDateField, ActivityTypeField, AvatarField,
    ValidateField, ValidityDateField, DriverCompanyTypeField, FileCountField, ClientDataField, PhotoField, DocNameField, DocStatusReasonField,
    RatingField,
    WarningField,
    PhoneField
} from '../../control/field/index'
import {
    VehicleLockButton, VehicleUnlockButton, VehicleDateEditButton,
    VehicleApproveButton, VehicleRejectButton,
    DocDateEditButton, ValidateButton, CustomerLockButton, CustomerUnlockButton
} from '../../control/button/index'

import { Status, DataStatus, FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER } from '../../util/constant'
import { BACKEND_FILE_URL } from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', borderRadius: '50%' },


});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};





const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>

        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {/* {typeof data != 'undefined' && typeof data.validated != 'undefined' && data.validated == FALSE &&
            <ValidateButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource} />
        } */}
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && (data.locked === Status.LOCKED || data.locked === parseInt(Status.LOCKED)) &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        }

        <DeleteButton resource="driver" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce chauffeur? Toutes les donnnées personnelles de ce chauffeur seront supprimées" />
        {/* <EditButton basePath={basePath} record={data} /> */}
        <ListButton basePath={basePath} />
        {/* <BackButton/> */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.show')}: {record ? `${record.email}` : ''}</span>;
};

const AvatarChangeButton = ({ record, basePath, resource }) => {
    return <AvatarButton basePath={basePath} record={record} resource={resource} />;
}

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    const viewerId = record.id;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            <ValidityDateField record={record} />
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer viewerId={viewerId} files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record} /> */}
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record} /><DocApproveButton record={record} /></div>

        </div>
    )
};

const VehicleDocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    const viewerId = record.id;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            {/* <ValidityDateField record={record} /> */}
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer viewerId={viewerId} files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record} /> */}
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record} /><DocApproveButton record={record} /></div>

        </div>
    )
};

const VehicleDateField = ({ record = {}, label }) => {
    const classes = useStyles();
    return record.circulation_date !== "" ? <div><br />
        <Typography variant="body2" className={classes.noColorLabel}>
            Date de la 1e mise en circulation: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label="" locales="fr-FR" /> : '--'}
            <VehicleDateEditButton record={record} />
        </Typography><br />
    </div> : '';
}
// className={classes.noColorLabel}
const BooleanField = ({ record = {}, source, label }) => {
    const classes = useStyles();
    const value = record[source] === "1" ? "Oui" : "Non";
    return <div>
        <Typography variant="body2">
            {value}€
        </Typography></div>;

}

BooleanField.defaultProps = {
    addLabel: true,
};

// const WalletBalanceField = ({ record = { wallet: { balance: 0 } } }) => {
//     const classes = useStyles();
//     const value = record.wallet.balance;
//     return <div>
//         <Typography variant="body1" style={{ fontWeight: 600, fontSize: 15 }}>
//             PORTEFEUILLE : {value}€
//         </Typography><WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?" /></div>;

// }

// WalletBalanceField.defaultProps = {
//     addLabel: false,
// };

const WALLET_ACTION_ADD = 9;
const WALLET_ACTION_END_CHARGE = 1;
const WALLET_ACTION_CANCEL_CHARGE = 2;
const WALLET_ACTION_CREATE_CHARGE = 3;
const WALLET_ACTION_TIPS = 4;
const WALLET_ACTION_RESET = 5;
const WALLET_ACTION_EDIT = 8;


const WalletActionField = ({ record = { action: 0 } }) => {
    const classes = useStyles();
    let text = "";
    if (record.action == WALLET_ACTION_END_CHARGE) text = "Commission de la course";
    else
        if (record.action == WALLET_ACTION_CANCEL_CHARGE) text = "Frais d'annulation";
        else
            if (record.action == WALLET_ACTION_CREATE_CHARGE) text = "Frais de création de la course";
            else
                if (record.action == WALLET_ACTION_TIPS) text = "Pourboire";
                else
                    if (record.action == WALLET_ACTION_RESET) text = "Remise à 0";
                    else
                        if (record.action == WALLET_ACTION_ADD) text = "Montant ajouté";
    return <div>
        <Typography variant="body2">
            {text}
        </Typography></div>;

}

WalletActionField.defaultProps = {
    addLabel: false,
};


// const AvatarChangeButton =  ({ record = {}, source,  label}) => {
//     return <AvatarButton user={record}/>;
// }

// AvatarChangeButton.defaultProps = {
//     addLabel: false,
// };


const VehicleContentPanel = ({ id, record, resource }) => {
    const viewerId = record.id;

    const classes = useStyles();
    console.log("Vehicle record " + JSON.stringify(record));
    var insuranceCertUrl = record.insurance_cert_url;
    // var hasInsuranceCert = insuranceCertUrl != null && insuranceCertUrl != "";

    if (typeof (insuranceCertUrl) !== 'undefined' && insuranceCertUrl !== 'undefined' && insuranceCertUrl !== null && insuranceCertUrl !== '' && !insuranceCertUrl.startsWith("http://") && !insuranceCertUrl.startsWith("https://"))
        insuranceCertUrl = BACKEND_FILE_URL + insuranceCertUrl;
    var transporterCardUrl = record.transporter_card_url;
    if (typeof (transporterCardUrl) !== 'undefined' && transporterCardUrl !== 'undefined' && transporterCardUrl !== null && transporterCardUrl !== '' && !transporterCardUrl.startsWith("http://") && !transporterCardUrl.startsWith("https://"))
        transporterCardUrl = BACKEND_FILE_URL + transporterCardUrl;
    var grayCardUrl = record.gray_card_url;
    if (typeof (grayCardUrl) !== 'undefined' && grayCardUrl !== 'undefined' && grayCardUrl !== null && grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;

    var grayCardVersoUrl = record.gray_card_verso_url;
    if (typeof (grayCardVersoUrl) !== 'undefined' && grayCardVersoUrl !== 'undefined' && grayCardVersoUrl !== null && grayCardVersoUrl !== '' && !grayCardVersoUrl.startsWith("http://") && !grayCardVersoUrl.startsWith("https://"))
        grayCardVersoUrl = BACKEND_FILE_URL + grayCardVersoUrl;

    var frontImageUrl = record.front_image_url;
    if (typeof (frontImageUrl) !== 'undefined' && frontImageUrl !== 'undefined' && frontImageUrl !== null && frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

    var files = [];
    files[0] = { file_url: frontImageUrl, name: 'Photo du véhicule (face avant, plaque visible)' };
    // files[1] = {file_url: rearImageUrl, name: 'Photo intérieur du véhicule'};
    files[1] = { file_url: insuranceCertUrl, name: "Certificat d'assurance" };
    files[2] = { file_url: grayCardUrl, name: 'Carte grise recto' };
    files[3] = { file_url: grayCardVersoUrl, name: 'Carte grise verso' };
    files[4] = { file_url: transporterCardUrl, name: 'Carte de transporteur' };

    // if (hasTransportInsurance)
    //     files[3] = { file_url: transportInsuranceCertUrl, name: "Attestation d'assurance avecc la plagque du véhicule" };

    return (

        <div style={styles.flexColumn}>
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer viewerId={viewerId} files={files} fileUrl={file.file_url} title={file.name} width='300' height='300' />
                })}
            </div>
            {/* <VehicleDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
        </div>
    );
};

const ContentTextField = ({ source, record = {}, label }) => {
    if (typeof (record) !== 'undefined') {
        var content = record['content'];
        if (typeof (content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig, "");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}

ContentTextField.defaultProps = {
    addLabel: true,
};

const CompanyField = ({ source, record = {}, label }) => {
    const classes = useStyles();
    if (typeof (record) !== 'undefined') {
        var userType = record['user_type'];
        if (typeof (userType) !== 'undefined' && userType !== null) {
            if (userType === USER_TYPE_COMPANY_AND_DRIVER) {
                return <div>
                    <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Société</Typography>
                    <Labeled label="Nom de société">
                        <Typography variant="body2">
                            {record.company_name}
                        </Typography>
                    </Labeled>
                    <Labeled label="Adresse du siègle social">
                        <Typography variant="body2">
                            {record.company_address}
                        </Typography>
                    </Labeled>

                    <Labeled label="Code postal">
                        <Typography variant="body2">
                            {record.postal_code}
                        </Typography>
                    </Labeled>

                    <Labeled label="Ville">
                        <Typography variant="body2">
                            {record.city}
                        </Typography>
                    </Labeled>
                    <br />
                    <Labeled label="IBAN">
                        <Typography variant="body2">
                            {record.iban}
                        </Typography>
                    </Labeled>
                    <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Représentant légal</Typography>
                    <Labeled label="Nom">
                        <Typography variant="body2">
                            {record.manager !== null && record.manager.last_name !== null ? record.manager.last_name : ""}
                        </Typography>
                    </Labeled>

                    <Labeled label="Prénom">
                        <Typography variant="body2">
                            {record.manager !== null && record.manager.first_name !== null ? record.manager.first_name : ""}
                        </Typography>
                    </Labeled>
                    <br />
                    <Labeled label="Date de naissance">
                        <Typography variant="body2">
                            {record.manager !== null && record.manager.birth_date !== null ? record.manager.birth_date : ""}
                        </Typography>
                    </Labeled>

                    <br />
                    <Labeled label="Adresse">
                        <Typography variant="body2">
                            {record.manager !== null && record.manager.address !== null ? record.manager.address : ""}
                        </Typography>
                    </Labeled>
                    <br />
                    <Labeled label="Pays">
                        <Typography variant="body2">
                            {record.manager !== null && record.manager.country_name !== null ? record.manager.country_name : "Belgique"}
                        </Typography>
                    </Labeled>

                </div>;
            }

            return <div>

                <Labeled label="Date de naissance">
                    <Typography variant="body2">
                        {record.birth_date}
                    </Typography>
                </Labeled>

                <Labeled label="Pays">
                    <Typography variant="body2">
                        {record.country_name}
                    </Typography>
                </Labeled>
                <br />
                <Labeled label="Adresse">
                    <Typography variant="body2">
                        {record.address}
                    </Typography>
                </Labeled>
                <br />
                <Labeled label="Code postal">
                    <Typography variant="body2">
                        {record.postal_code}
                    </Typography>
                </Labeled>

                <Labeled label="Ville">
                    <Typography variant="body2">
                        {record.city}
                    </Typography>
                </Labeled>
                <br />
                <Labeled label="IBAN">
                    <Typography variant="body2">
                        {record.iban}
                    </Typography>
                </Labeled>
            </div>;
        }

    }
    return <span></span>
}

CompanyField.defaultProps = {
    addLabel: false,
};

const ListEvent = props => (
    // <List title=" " {...props} filter={{driver_id: props.id}} exporter={false}  bulkActionButtons={false}>
    <Datagrid expandHeader={false}>
        <FormattedDateField source="event_date" label="Date" locales="fr-FR" />
        {/* <TextField source="plate_number" label="Numéro de véhicule"/> */}

        <ReferenceField label="Numéro de véhicule" source="vehicle_id" reference="vehicle" link="show">
            <TextField source="plate_number" />
        </ReferenceField>


    </Datagrid>
    // </List>
);

const WalletBalanceField = ({ record = { wallet: { balance: 0 } } }) => {
    // const classes = useStyles();
    const value = record.wallet.balance;
    return <div>
        <Typography variant="body1" style={{ fontWeight: 600, fontSize: 15 }}>
            Montant du Wallet : {value} Franc CFA
        </Typography>
        {/* <WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?"/> */}
    </div>;

}

WalletBalanceField.defaultProps = {
    addLabel: false,
};


// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

// import { withStyles } from "material-ui/styles";



const ShowDriver = ({ staticContext, permissions, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    if (permissions !== 'Admin') return <span>Aucune permission</span>;

    return (

        <Show component="div" title={<Title />} actions={<ShowActions />} {...props}>
            <TabbedShowLayout redirect="list">
                <Tab label="Profil">
                    {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Profil</Typography> */}

                    {/* <AvatarField source="photo_url" label="" size='200' /> */}
                    {/* <DriverCompanyTypeField label="Type de chauffeur"/> */}
                    {/* <PhotoField source="photo_url" label=""/> */}
                    {/* <div style={{"width":"60px" , "height":"60px"}}> */}
                    {/* <ImageField source="photo_url" label="" className="avatar"/> */}
                    {/* </div> */}
                    {/* <ImageField source="photo_url" label="" style={{"width":"60px" , "height":"60px", "border-radius": "50%"}}/> */}
                    {/* <AvatarChangeButton/> */}
                    {/* <TextField source="user_code" label="Identifiant unique"/> */}
                    {/* <TextField source="id" label="ID"/> */}
                    {/* <GenderField label={translate('civility')}/> */}
                    {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField> */}
                    <WarningField source="completed" label="Etat du profil" />
                    {/* <ReferenceField label="Nom du partenaire" source="company_id" reference="driver_company" link="">
                        <TextField source="company_name" />
                    </ReferenceField>
                    <TextField source="company_name" label="Nom de société" /> */}
                    <div />
                    <TextField source="last_name" label="Nom" className={classes.inlineBlock} />
                    <TextField source="first_name" label="Prénom" className={classes.inlineBlock} />
                    <div />
                    <PhoneField label="Mobile" className={classes.inlineBlock} />
                    <TextField source="email" label="Email" className={classes.inlineBlock} />
                    <div />

                    <FormattedDateField source="birth_date" label="Date de naissance" locales="fr-FR" />
                    <TextField source="company_name" label="Nom de société" />
                    <TextField source="company_address" label="Adresse" />
                    <TextField source="postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="city" label="Ville" className={classes.inlineBlock} />
                    <TextField source="country_name" label="Pays" className={classes.inlineBlock} />
                    <div />
                    <RatingField source="points" label="Notations du chauffeur" />
                    {/* <ActivityTypeField source="activity_type" label = "Type d'activité"/>
                <ValidateField source="animal_accepted" label="Animal accepté"/> */}
                    {/* <TextField source="wallet.balance" label="Portefeuille(€)" className={classes.inlineBlock}/> */}
                    {/* <br/> */}
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}

                    {/* <br/>
                <Divider/> */}
                    {/* <br/> */}
                    {/* <Typography variant="body2">État</Typography> */}

                    {/* </Tab> */}

                    {/* <Tab label="Infos de société"> */}
                    {/* <AvatarField source="photo_url" label="" size='80'/> */}
                    {/* <PhotoField source="photo_url" label=""/>
                <TextField source="id" label="ID"/> */}
                    {/* <GenderField label={translate('civility')}/> */}
                    {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField>
                <div/> */}
                    {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Société</Typography> */}

                    {/* <TextField source="company_name" label="Nom de société" />
                    <TextField source="company_address" label="Adresse" />
                    <TextField source="postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="city" label="Ville" className={classes.inlineBlock} /> */}
                    <div />
                    {/* <TextField source="siren_number" label="Numéro SIREN" />
                    <TextField source="iban" label="IBAN" /> */}
                    {/* <CompanyField/> */}
                    {/* <Divider/> */}
                    <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
                    {/* <TextField source="activation_code" label="Code d'activation" /> */}
                    {/* <ValidateField source="activated" label='Activé' className={classes.inlineBlock} /> */}
                    {/* <ValidateField source="validated" label='Validé' className={classes.inlineBlock} /> */}
                    <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock} />
                    {/* <TextField source="postal_code" label="Code postal"  className={classes.inlineBlock}/>
                <TextField source="city" label="Ville"  className={classes.inlineBlock}/> */}

                    {/* <TextField source="phone" label = "Téléphone"/> */}
                    {/* <TextField source="iban" label = "IBAN"/> */}
                    {/* <TextField source="siren_number" label = "Nº SRIEN"/> */}
                    {/* <TextField source="vat_no" label = "Nº de TVA intracommunautaire"/> */}
                    <br />
                    {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'none', fontSize:13 }}>Représentant légal</Typography>
                <TextField source="manager.last_name" label = "Nom" className={classes.inlineBlock}/>
                <TextField source="manager.first_name" label = "Prénom" className={classes.inlineBlock}/>
                <FormattedDateField source="manager.birth_date" label="Date de naissance"  locales="fr-FR"/>
                <TextField source="manager.address" label = "Adresse"/>
                <TextField source="manager.country_code" label = "Pays"/> */}

                    {/* <TextField source="email" label = "Email" className={classes.inlineBlock}/> */}
                    {/* <br/> */}
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                    {/* <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/> */}
                    {/* <br/>
                <Divider/> */}
                    {/* <br/> */}
                    {/* <Typography variant="body2">État</Typography> */}
                    {/* <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/> */}
                </Tab>

                {/* <Tab label="Paramètres">
                <BooleanField source="settings.3rd_payment" label="Tiers payant"/>
                <DriverSettingsButton/>
            </Tab> */}
                <Tab label="Wallet">
                    <WalletBalanceField />
                    <WalletEditButton />
                    <br />
                    <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Historique de changement</Typography>
                    <ReferenceManyField pagination={<Pagination />} reference="wallet_action" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="">
                            {/* <TextField source="create_time" label="Date"/> */}
                            <FormattedDateField source="create_time" label="Date" format="format_date_time" locales="fr-FR" />
                            <TextField source="value" label="Valeur(Franc CFA)" />
                            <WalletActionField source="action" label="Action" />
                        </Datagrid>
                    </ReferenceManyField>

                </Tab>

                <Tab label="Documents">
                    <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                        <Datagrid expand={<DocContentPanel />} expandHeader={<div />}>
                            {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                            <DocNameField label="Nom du document" />
                            <FileCountField label="Fichiers" />
                            <DocStatusField source="status" label="État" />
                            <DocStatusReasonField label="Motif de refus" />
                            {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                            {/* <OpenFileButton/> */}

                        </Datagrid>
                    </ReferenceManyField>
                    {/* <FileField source="files" src='url' title='title' label="Fichiers" target="_blank"/> */}
                    {/* <FileViewer source="files" src='url' /> */}
                </Tab>
                <Tab label="Véhicules">
                    <ReferenceManyField pagination={<Pagination />} reference="vehicle" target="driver_id" addLabel={false}>
                        <Datagrid expand={<VehicleContentPanel />}>
                            <TextField source="plate_number" label="Immatriculation" className={classes.colorValue} />
                            <TextField source="brand_name" label="Marque" />
                            <TextField source="model_name" label="Modèle" />
                            {/* <TextField source="vehicle_type_name" label="Type" /> */}
                            <TextField source="seats" label="Nombre de passagers max." />
                            <TextField source="color" label="Couleur" />
                            <ValidateField source="air_conditioner" label="Climatisation" />
                            <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" />
                            <FormattedDateField source="control_date" label="Date de la prochaine visite technique" locales="fr-FR" />

                            <ValidateField source="is_default" label="Par défaut" />
                            {/* <DataStatusField source="status" label="État" /> */}


                            {/* <VehicleDateEditButton /> */}
                        </Datagrid>
                    </ReferenceManyField>

                </Tab>
                <Tab label="Commandes">
                    <ReferenceManyField pagination={<Pagination />} reference="order_driver" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <FormattedDateField source="create_time" label="Date\heure" format="format_date_time" />
                            <TextField source="order_number" label="Nº de commande" />
                            {/* <ClientDataField source="last_name" label="Nom du client" />
                            <ClientDataField source="first_name" label="Prénom du client" /> */}
                            <ReferenceField label="Client" source="client_id" reference="client" link="" perPage={999999}>
                                <TextField source="full_name" />
                            </ReferenceField>
                            <TextField source="from_address" label="Adresse de départ" />
                            <TextField source="to_address" label="Adresse d'arrivée" />
                            <TextField source="total" label="Prix(Franc CFA)" />
                            <OrderStatusField source="status" label="État" />
                            {/* <TextField source="rating_on_driver" label="Note attribué par le client"/> */}
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>


        </Show>
    );
};


export default ShowDriver;

{/* <Tab label="Informations de la société">
                <UserTypeField label="Type d'entreprise"/>
                <TextField source="company_name" label = "Nom"/>
                <TextField source="company_address" label = "Adresse"/>
                <div/>
                <TextField source="postal_code" label="Code postal" className={classes.inlineBlock}/>
                <TextField source="city" label="Ville" className={classes.inlineBlock}/>                
            </Tab> */}