
import React, { Link, Component } from 'react';
import { Filter, TextInput, ReferenceField, Pagination, DateField, ReferenceManyField, Datagrid, TextField, Show, useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form'
import Divider from '@material-ui/core/Divider';
import { DocApproveButton, DocRejectButton, DeleteButton, AvatarButton } from '../../control/button/index';

import ImageViewer from '../../control/viewer/image-viewer';

import CardActions from '@material-ui/core/CardActions';

import {
    DocNameField, FileCountField, ActivityTypeField,
    ClientDataField, ValidityDateField, PhotoField,
    DocStatusReasonField, DocStatusField, OrderStatusField, FormattedDateField, DriverStatusField, AvatarField, GenderField, ValidateField,
    DriverCompanyTypeField,
    WarningField,
    WarningListField,
    PhoneField
} from '../../control/field/index'
import {
    VehicleLockButton, VehicleUnlockButton, VehicleDateEditButton,
    VehicleApproveButton, VehicleRejectButton,
    DocDateEditButton, ValidateButton, CustomerLockButton, CustomerUnlockButton
} from '../../control/button/index'

import { Status, DataStatus, FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER, USER_TYPE_AUTO_ENTERPRENER, ACTIVITY_TYPE_ATR, ACTIVITY_TYPE_VTC }
    from '../../util/constant'
import { BACKEND_FILE_URL, theme } from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';
import WalletEditButton from '../../control/button/wallet-edit-button';
import MediaViewer from '../../control/viewer/media-viewer';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', }

});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// const DocNameField = ({record, label}) => {
//     // const classes = useStyles();
//     console.log('Doc record: ' + JSON.stringify(record));
//     // if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
//     //     const nameObj = JSON.parse(record.name);
//     //     // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
//     //     // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
//     //     return <div><span>{nameObj.fr}</span></div>;
//     // }
//     if(typeof(record) !== 'undefined' && typeof(record.doc_name) !== 'undefined'){
//         // const nameObj = JSON.parse(record.name);
//         // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
//         // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
//         return <div><span>{record.doc_name}</span></div>;
//     }
//     return <div/>;

// }

// DocNameField.defaultProps = {
//     addLabel:true
// }

// const FileCountField = ({record, label}) => {
//     const classes = useStyles();
//     console.log('Doc record: ' + JSON.stringify(record));
//     if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
//         return <div><span className={classes.colorValue}>{record.files.length}</span></div>
//     }

//     return <div><span className={classes.colorValue}>0</span></div>

// }

// FileCountField.defaultProps = {
//     addLabel:true
// }


const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>

        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {/* {typeof data != 'undefined' && typeof data.validated != 'undefined' && data.validated == FALSE &&
            <ValidateButton basePath={basePath} record={data} resource={resource} />
        } */}
        {/* {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        } */}
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && (data.locked === Status.LOCKED || data.locked === parseInt(Status.LOCKED)) &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        }

        <DeleteButton resource="driver" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce chauffeur? Toutes les donnnées personnelles de ce chauffeur seront supprimées" />
        {/* <EditButton basePath={basePath} record={data} /> */}
        <ListButton basePath={basePath} />
        {/* <BackButton/> */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver_company.show')}: {record ? `${record.email}` : ''}</span>;
};



// const UserTypeField = ({ record = {}, label}) => {
//     return <div>
//         <Typography variant="body2">
//             {record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}
//         </Typography>
//     </div>;
// }

// UserTypeField.defaultProps = {
//     addLabel: true,
// };


// const DocStatusReasonField = ({ record = {}, label}) => {
//     const classes = useStyles();
//     const statusData = record.status_data;
//     if(typeof(statusData) !== 'undefined' && statusData !== null && statusData !== ''){
//         const statusObj = JSON.parse(statusData); 
//         // return <span>{statusObj.reason}</span>;
//         return <Typography variant="body2" className={classes.colorPrimary}>{statusObj.reason}</Typography>
//     }
//     //     return <Typography variant="body2">
//     //     {statusData.reason}
//     // </Typography>
//     return <span></span>;
// }

// DocStatusReasonField.defaultProps = {
//     addLabel: true,
// };

// const ClientDataField = ({ record = {}, source, label}) => {
//     const client = record.client;
//     if(typeof(client) !== 'undefined' && client !== null && client !== ''){
//         const clientObj = JSON.parse(client); 
//         return <Typography variant="body2">{clientObj[source]}</Typography>
//     }

//     return <span></span>;
// }

// ClientDataField.defaultProps = {
//     addLabel: true,
// };


// const ValidityDateField = ({ record = {}, label}) => {
//     const classes = useStyles();
//     return record.date_id !== "" ? <div><br/>
//             <Typography variant="body2" className={classes.noColorLabel}>
//             Date de validité: {record.date !== "" ? <DateField className={classes.colorValue} source="date" record={record} label=""  locales="fr-FR"/> : '--'}
//             <DocDateEditButton record={record}/>
//         </Typography><br/>
//     </div> : '';
// }

// const PhotoField = ({ record = {}, source, label}) => {
//     // const classes = useStyles();
//     return <ImageViewer files={[]} fileUrl={record[source]} title={label} width='60' height='60' round='50%'/>

// }

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    // console.log("File record " + JSON.stringify(record));
    var files = record.files;
    const viewerId = record.id;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            {/* <ValidityDateField record={record} /> */}
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <MediaViewer viewerId={viewerId} files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record} /> */}
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record} /><DocApproveButton record={record} /></div>

        </div>
    )
};

const VehicleDateField = ({ record = {}, label }) => {
    const classes = useStyles();
    return record.circulation_date !== "" ? <div><br />
        <Typography variant="body2" className={classes.noColorLabel}>
            Date de la 1e mise en circulation: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label="" locales="fr-FR" /> : '--'}
            <VehicleDateEditButton record={record} />
        </Typography><br />
    </div> : '';
}

const VehicleContentPanel = ({ id, record, resource }) => {
    const viewerId = record.id;
    const classes = useStyles();
    console.log("Vehicle record " + JSON.stringify(record));
    var insuranceCertUrl = record.insurance_cert_url;
    // var hasInsuranceCert = insuranceCertUrl != null && insuranceCertUrl != "";

    if (typeof (insuranceCertUrl) !== 'undefined' && insuranceCertUrl !== 'undefined' && insuranceCertUrl !== null && insuranceCertUrl !== '' && !insuranceCertUrl.startsWith("http://") && !insuranceCertUrl.startsWith("https://"))
        insuranceCertUrl = BACKEND_FILE_URL + insuranceCertUrl;
    var transporterCardUrl = record.transporter_card_url;
    if (typeof (transporterCardUrl) !== 'undefined' && transporterCardUrl !== 'undefined' && transporterCardUrl !== null && transporterCardUrl !== '' && !transporterCardUrl.startsWith("http://") && !transporterCardUrl.startsWith("https://"))
        transporterCardUrl = BACKEND_FILE_URL + transporterCardUrl;
    var grayCardUrl = record.gray_card_url;
    if (typeof (grayCardUrl) !== 'undefined' && grayCardUrl !== 'undefined' && grayCardUrl !== null && grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;

    var grayCardVersoUrl = record.gray_card_verso_url;
    if (typeof (grayCardVersoUrl) !== 'undefined' && grayCardVersoUrl !== 'undefined' && grayCardVersoUrl !== null && grayCardVersoUrl !== '' && !grayCardVersoUrl.startsWith("http://") && !grayCardVersoUrl.startsWith("https://"))
        grayCardVersoUrl = BACKEND_FILE_URL + grayCardVersoUrl;

    var frontImageUrl = record.front_image_url;
    if (typeof (frontImageUrl) !== 'undefined' && frontImageUrl !== 'undefined' && frontImageUrl !== null && frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

    var files = [];
    files[0] = { file_url: frontImageUrl, name: 'Photo du véhicule (face avant, plaque visible)' };
    // files[1] = {file_url: rearImageUrl, name: 'Photo intérieur du véhicule'};
    files[1] = { file_url: insuranceCertUrl, name: "Certificat d'assurance" };
    files[2] = { file_url: grayCardUrl, name: 'Carte grise recto' };
    files[3] = { file_url: grayCardVersoUrl, name: 'Carte grise verso' };
    files[4] = { file_url: transporterCardUrl, name: 'Carte de transporteur' };

    return (

        <div style={styles.flexColumn}>
            <div style={styles.images}>
                {files.map(file => {
                    return <MediaViewer viewerId={viewerId} files={files} fileUrl={file.file_url} title={file.name} width='300' height='300' />
                })}
            </div>
            {/* <VehicleDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
        </div>
    );
};
const VehicleContentPanelX = ({ id, record, resource }) => {
    const classes = useStyles();
    // console.log("File record " + JSON.stringify(record));
    var files = record.files;
    const viewerId = record.id;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            {/* <ValidityDateField record={record} /> */}
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <MediaViewer viewerId={viewerId} files={files} fileUrl={file.file_url} title={file.doc_name} width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record} /> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span>
                <DocRejectButton record={record} /><DocApproveButton record={record} /></div> */}

        </div>
    )
};

const ContentTextField = ({ source, record = {}, label }) => {
    if (typeof (record) !== 'undefined') {
        var content = record['content'];
        if (typeof (content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig, "");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}

ContentTextField.defaultProps = {
    addLabel: true,
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable />
        <TextInput label="Mobile" source="mobile" alwaysOn resettable />
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/>
        <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
        
        <TextInput label="Ville" source="city" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/>
        <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/>

        <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);

// filter={{company_id: record.id}} 
const DriverList = ({ record = {}, ...props }) => {
    console.log('Driver list record id ' + record.id);
    return <ReferenceManyField reference="driver" target="company_id" addLabel={false}>
        {/* <List filter={{company_id: record.id}}  filters={<ListFilter />}> */}

        <Datagrid rowClick="show" {...props}>
            {/* <AvatarField source="photo_url" label="" /> */}
            <TextField source="id" label="ID" />
            <TextField source="last_name" label="Nom" />
            <TextField source="first_name" label="Prénom" />
            <TextField source="mobile" label="Mobile" />
            <TextField source="email" label="Email" />
            <FormattedDateField source="birth_date" label="Date de naissance" locales="fr-FR" />
            <DriverStatusField source="online_status" label="Statut" />
            <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
            {/* <ValidateField source="activated" label='Activé'/>
                    <ValidateField source="validated" label='Validé'/>*/}
            <ValidateField source="locked" label='Verrouillé' />
        </Datagrid>

        {/* </List> */}
    </ReferenceManyField>
}

const WALLET_ACTION_ADD = 9;
const WALLET_ACTION_END_CHARGE = 1;
const WALLET_ACTION_CANCEL_CHARGE = 2;
const WALLET_ACTION_CREATE_CHARGE = 3;
const WALLET_ACTION_TIPS = 4;
const WALLET_ACTION_RESET = 5;
const WALLET_ACTION_EDIT = 8;


const WalletActionField = ({ record = { action: 0 } }) => {
    const classes = useStyles();
    let text = "";
    if (record.action == WALLET_ACTION_END_CHARGE) text = "Commission de la course";
    else
        if (record.action == WALLET_ACTION_CANCEL_CHARGE) text = "Frais d'annulation";
        else
            if (record.action == WALLET_ACTION_CREATE_CHARGE) text = "Frais de création de la course";
            else
                if (record.action == WALLET_ACTION_TIPS) text = "Pourboire";
                else
                    if (record.action == WALLET_ACTION_RESET) text = "Remise à 0";
                    else
                        if (record.action == WALLET_ACTION_ADD) text = "Montant ajouté";
    return <div>
        <Typography variant="body2">
            {text}
        </Typography></div>;

}

WalletActionField.defaultProps = {
    addLabel: false,
};

const WalletBalanceField = ({ record = { wallet: { balance: 0 } } }) => {
    // const classes = useStyles();
    const value = record.wallet.balance;
    return <div>
        <Typography variant="body1" style={{ fontWeight: 600, fontSize: 15 }}>
            Montant du Wallet : {value} Franc CFA
        </Typography>
        {/* <WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?"/> */}
    </div>;

}

WalletBalanceField.defaultProps = {
    addLabel: false,
};

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

const ShowDriver = ({ staticContext, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (

        <Show component="div" title={<Title />} actions={<ShowActions />} {...props}>
            <TabbedShowLayout redirect="list">
                <Tab label="Profil">
                    {/* <PhotoField source="logo_url" label="" /> */}
                    {/* <DriverCompanyTypeField label="Type d'entreprise" /> */}
                    {/* <ActivityTypeField label="Type d'activité" /> */}
                    <WarningField source="completed" label="Etat du profil" />
                    <TextField source="company_name" label="Nom de société" />
                    <TextField source="reg_number" label="Numéro RCCM de la société" />

                    {/* <TextField source="mobile" label="Mobile" className={classes.inlineBlock} /> */}
                    <PhoneField label="Mobile" className={classes.inlineBlock} />
                    <TextField source="email" label="Email" className={classes.inlineBlock} />
                    <div />
                    <TextField source="company_address" label="Adresse" />
                    <div />
                    <TextField source="postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="city" label="Ville" className={classes.inlineBlock} />
                    <TextField source="country_name" label="Pays" className={classes.inlineBlock} />
                    <div />
                    <TextField source="last_name" label="Nom du dirigeant" className={classes.inlineBlock} />
                    <TextField source="first_name" label="Prénom du dirigeant" className={classes.inlineBlock} />
                    <FormattedDateField source="birth_date" label="Date de naissance du dirigeant" locales="fr-FR" className={classes.inlineBlock} />

                    <div />
                    {/* <TextField source="phone" label="Téléphone" className={classes.inlineBlock} /> */}

                    <TextField source="taxpayer_number" label="Numéro de contribuable de la société" />

                    {/* <ValidateField source="activated" label='Activé' className={classes.inlineBlock} /> */}
                    {/* <ValidateField source="validated" label='Validé' className={classes.inlineBlock} /> */}
                    <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock} />
                    <br />


                </Tab>
                <Tab label="Wallet">
                    <WalletBalanceField />
                    <WalletEditButton />
                    <br />
                    <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Historique de changement</Typography>
                    <ReferenceManyField pagination={<Pagination />} reference="wallet_action" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="">
                            {/* <TextField source="create_time" label="Date"/> */}
                            <FormattedDateField source="create_time" label="Date" format="format_date_time" locales="fr-FR" />
                            <TextField source="value" label="Valeur(Franc CFA)" />
                            <WalletActionField source="action" label="Action" />
                        </Datagrid>
                    </ReferenceManyField>

                </Tab>

                <Tab label="Documents">
                    <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                        <Datagrid expand={<DocContentPanel />} expandHeader={<div />}>
                            {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                            <DocNameField label="Nom du document" />
                            <FileCountField label="Fichiers" />
                            <DocStatusField source="status" label="État" />
                            <DocStatusReasonField label="Motif de refus" />
                            {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                            {/* <OpenFileButton/> */}

                        </Datagrid>
                    </ReferenceManyField>
                    {/* <FileField source="files" src='url' title='title' label="Fichiers" target="_blank"/> */}
                    {/* <FileViewer source="files" src='url' /> */}
                </Tab>

                <Tab label="Chauffeurs">
                    {/* <DriverList/> */}
                    <ReferenceManyField pagination={<Pagination />} reference="driver" target="company_id" addLabel={false} filter={{ user_type: 7 }}>

                        <Datagrid rowClick="show" {...props}>
                            {/* <AvatarField source="photo_url" label="" /> */}
                            {/* <TextField source="id" label="ID" /> */}
                            <TextField source="company_name" label="Nom de la société" />
                            <TextField source="company_address" label="Adresse" />
                            <TextField source="postal_code" label="Code postal" />
                            <TextField source="city" label="Ville" />
                            <TextField source="country_name" label="Pays" />
                            <TextField source="mobile" label="Mobile" />
                            <TextField source="email" label="Email" />
                            {/* <FormattedDateField source="birth_date" label="Date de naissance du dirigeant" locales="fr-FR" /> */}
                            <DriverStatusField source="online_status" label="Statut" />
                            <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
                            {/* <ValidateField source="activated" label='Activé'/>
                    <ValidateField source="validated" label='Validé'/>*/}
                            <ValidateField source="locked" label='Verrouillé' />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

                <Tab label="Véhicules">
                    <ReferenceManyField reference="vehicle" target="company_id" addLabel={false}>
                        {/* <Datagrid expand={<VehicleContentPanel />}> */}
                        <Datagrid rowClick="show" {...props}>
                            {/* <Datagrid rowClick=""> */}
                            <TextField source="plate_number" label="Immatriculation" className={classes.colorValue} />
                            <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand" link="" perPage={9999999}>
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="Modèle" source="model_id" reference="vehicle_model" link="" perPage={9999999}>
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="" perPage={9999999}>
                                <TextField source="name" />
                            </ReferenceField>
                            {/* <TextField source="brand_name" label="Marque" />
                            <TextField source="model_name" label="Modèle" /> */}
                            {/* <TextField source="vehicle_type_name" label="Type" /> */}
                            {/* <TextField source="seats" label="Nombre de passagers max." /> */}
                            <TextField source="color" label="Couleur" />
                            {/* <ValidateField source="air_conditioner" label="Climatisation" /> */}
                            {/* <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" /> */}
                            {/* <FormattedDateField source="control_date" label="Date de la prochaine visite technique" locales="fr-FR" /> */}


                            <ReferenceField label="Chauffeur" source="driver_id" reference="driver_all_type" link="" perPage={9999999}>
                                <TextField source="full_name" />
                            </ReferenceField>
                            {/* <ValidateField source="is_default" label="Par défaut" /> */}

                            {/* <ValidateField source="is_default" label="Par défaut"/> */}
                            {/* <DataStatusField source="status" label="État"/> */}
                            <ValidateField source="locked" label="Vérrouillé" />


                        </Datagrid>
                    </ReferenceManyField>

                </Tab>
                {/* <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation"  locales="fr-FR"/> */}
                {/* <FormattedDateField source="control_date" label="Date du contrôle technique"  locales="fr-FR"/> */}
                {/* <VehicleDateEditButton/> */}
                <Tab label="Commandes">
                    <ReferenceManyField pagination={<Pagination />} reference="order" target="company_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <FormattedDateField source="create_time" label="Date\heure" format="format_date_time" />
                            <TextField source="order_number" label="Nº de commande" />
                            {/* <ClientDataField source="last_name" label="Nom du client" />
                            <ClientDataField source="first_name" label="Prénom du client" /> */}
                            <ReferenceField label="Client" source="client_id" reference="client" link="" perPage={999999}>
                                <TextField source="full_name" />
                            </ReferenceField>
                            <ReferenceField label="Chauffeur" source="driver_id" reference="driver_all_type" link="" perPage={999999}>
                                <TextField source="full_name" />
                            </ReferenceField>
                            <TextField source="from_address" label="Adresse de départ" />
                            <TextField source="to_address" label="Adresse d'arrivée" />
                            <TextField source="total" label="Prix(Franc CFA)" />
                            <OrderStatusField source="status" label="État" />
                            {/* <TextField source="rating_on_driver" label="Note attribué par le client" /> */}
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

            </TabbedShowLayout>


        </Show>
    );
};


export default ShowDriver;