import React, { Fragment } from 'react';
import { Pagination, ReferenceInput, AutocompleteInput, ReferenceField, usePermissions, List, Datagrid, TextField, DateField, EditButton, Filter, TextInput, SelectInput, useTranslate } from 'react-admin';


// import BatchDeleteButton from '../../control/button/batch-delete-button';


import { GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER, USER_TYPE_DRIVER_COMPANY, ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_ATR } from '../../util/constant';

import {
    WarningField,
    FormattedDateField, DriverStatusField, VerifyField, AvatarEmailField, FullNameField, GenderField,
    DriverCompanyTypeField, DriverCompanyTypeFilterField, ActivityTypeFilterField, ActivityTypeField,
    GenderFilterField, LockField, ValidateField, AvatarField,
    WarningListField,
    PhoneField
} from '../../control/field/index'
import { NoticeSendButton } from '../../control/button/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <NoticeSendButton {...props} toClient={false} />
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.list')}</span>;
};

var driverTypes = [];
driverTypes.push({ id: USER_TYPE_AUTO_ENTERPRENER, name: "Personne physique" });
driverTypes.push({ id: USER_TYPE_COMPANY_AND_DRIVER, name: "Société" });

var activityTypes = [];
activityTypes.push({ id: ACTIVITY_TYPE_VTC, name: "VTC" });
activityTypes.push({ id: ACTIVITY_TYPE_ATR, name: "Transport léger des marchandises" });

// const UserTypeField = ({ record = {}, label}) => {
//     return <div>
//         <span class>{record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}</span>
//     </div>;
// }

// const UserTypeFilterField = ({ record }) => {
//     return <span>{record.id == USER_TYPE_AUTO_ENTERPRENER ? 'Auto-entrepreneur' : 'Autre'}</span>;
// };

// const FormattedDateField = ({ record = {}, source, label, locales, className}) => {
//     // const classes = useStyles();
//     if(typeof(record) === 'undefined' || 
//         typeof(record[source]) === 'undefined' 
//         || record[source] === "")
//         return <span></span>

//     moment.locale('fr');
//     const value = moment(record[source], "YYYY-MM-DD HH:mm:ss");
//     return <spam>{value.format('DD/MM/YYYY')}</spam>
// }

// FormattedDateField.defaultProps = {
//     addLabel: true,
// };

var genders = [];
genders.push({ id: GENDER_MALE, name: 'Monsieur' });
genders.push({ id: GENDER_FEMALE, name: "Madame" });

var profileStatus = [];
profileStatus.push({ id: 1, name: 'Complété' });
profileStatus.push({ id: 0, name: "Non complété" });

var lockeds = [];
lockeds.push({ id: 0, name: 'Non-verrouillé' });
lockeds.push({ id: 1, name: "Verrouillé" });

var validateds = [];
validateds.push({ id: 0, name: 'Non-validé' });
validateds.push({ id: 1, name: "Validé" });

var activateds = [];
activateds.push({ id: 0, name: 'Non-activé' });
activateds.push({ id: 1, name: "Activé" });

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable />
        <TextInput label="Mobile" source="mobile" alwaysOn resettable />
        <TextInput label="Nom de société" source="company_name" alwaysOn resettable />
        {/* <TextInput label="Prénom" source="first_name" alwaysOn resettable /> */}
        {/* <ReferenceInput label="Partenaire" source="company_id"
            reference="driver_company" perPage={999999} sort={{ field: 'company_name', order: 'ASC' }}
            alwaysOn>
            <AutocompleteInput optionText="company_name" />
        </ReferenceInput> */}

        {/* <ReferenceInput label="Marque" source="brand_id" emptyText="Tous" 
            sort={{field: 'name', order: 'ASC'}}
            reference="vehicle_brand" perPage={99999} alwaysOn resettable>
            <AutocompleteInput optionText="name"
                
            />
        </ReferenceInput> */}

        {/* <TextInput label="Nom de Jockey" source="user_code" alwaysOn resettable/> */}

        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<DriverCompanyTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}

        {/* <SelectInput label="Activité" source="activity_type" alwaysOn choices={activityTypes} 
            optionText={<ActivityTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}
        {/* <TextInput label="Adresse" source="company_address" resettable />
        <TextInput label="Ville" source="city" resettable /> */}
        {/* <TextInput label="Code postal" source="postal_code" resettable /> */}
        {/* <TextInput label="Identifiant unique" source="user_code" resettable/> */}
        {/* <SelectInput label="Identifiant unique" source="user_code" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        /> */}
        <SelectInput label="Verrouillage" source="locked" choices={lockeds} alwaysOn
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        />
        <SelectInput label="Etat du profil" source="completed" choices={profileStatus} alwaysOn
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        />
        {/* <SelectInput label="Validation" source="validated" choices={validateds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        /> */}
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;


const ListDriver = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions !== 'Admin') return <span>Aucune permission</span>;
    return (
        <List pagination={<ListPagination />} title={<Title />} {...props} perPage={50} filter={{ user_type: 2 }}
            filters={<ListFilter permissions={permissions} />}
            bulkActionButtons={<BulkActionButtons />} exporter={false}>
            <Datagrid rowClick="show">
                {/* <AvatarField source="photo_url" label="" /> */}
                <WarningListField source="completed" label="Etat du profil" addLabel={false} />
                {/* <ReferenceField label="Nom du partenaire" source="company_id" reference="driver_company" link="">
                    <TextField source="company_name" />
                </ReferenceField> */}

                {/* <TextField source="user_code" label="Nom de Jockey"/> */}
                {/* <GenderField source="gender" label={translate('civility')}/> */}
                {/* <TextField source="last_name" label="Nom du dirigean" />
                <TextField source="first_name" label="Prénom  du dirigean" /> */}
                {/* <TextField source="balance" label="Wallet(€)"/> */}
                {/* <DriverCompanyTypeField source="user_type" label="Type du chauffeur"/> */}
                {/* <ActivityTypeField source="activity_type" label="Type d'activité"/> */}
                {/* <TextField source="company_name" label="Nom de société"/> */}
                {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField> */}
                <TextField source="company_name" label="Nom de société" />
                {/* <TextField source="company_address" label="Adresse" />
                <TextField source="postal_code" label="Code postal" />
                <TextField source="city" label="Ville" /> */}
                {/* <TextField source="mobile" label="Mobile" /> */}
                <PhoneField label="Mobile" />
                {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
                <TextField source="email" label="Email" />
                <DriverStatusField source="online_status" label="Statut" />
                <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
                {/* <ValidateField source="activated" label='Activé' /> */}
                {/* <ValidateField source="validated" label='Validé' /> */}
                <ValidateField source="locked" label='Verrouillé' />
                {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
                {/* <TextField source="creator.email" label="Créateur"/> */}
                {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
                {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
                {/* <EditButton/> */}
            </Datagrid>
        </List>);
};

export default ListDriver;

